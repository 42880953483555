import { PermissionGuard } from '@/components/auth/permission-guard';
import { TextInputBase } from '@/components/form/common/text-input';
import { Modal } from '@/components/modal';
import { PageTitle } from '@/components/page-title';
import { Table } from '@/components/table';
import { Tag } from '@/components/tag';
import { UpsertUserDrawer } from '@/components/upsert-user-drawer';
import { userKeys } from '@/config/query-keys';
import { staffRouterPath } from '@/config/route-paths.config';
import { useCreateInvitationMutation } from '@/hooks/create-hooks/use-create-invitation-mutation';
import { useUsers } from '@/hooks/query-hooks/use-users';
import { useUpdateUserMutation } from '@/hooks/update-hooks/update-user-mutation';
import { useModal } from '@/hooks/use-modal';
import { useResendInvitationMutation } from '@/hooks/use-resend-invitation-mutation';
import { useSuspendUserMutation } from '@/hooks/use-suspend-user-mutation';
import { queryClient } from '@/libs/react-query';
import { Permission } from '@/types/auth';
import { getErrorMessage } from '@/utils/get-error-message';
import { UpdateUserDto, UsersAndInvites } from '@admissions-support/types';
import { createColumnHelper } from '@tanstack/react-table';
import { Edit01, SearchLg, UserX02 } from '@untitled-ui/icons-react';
import { useState } from 'react';
import {
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

const resetUsersQuery = async () => {
  await queryClient.invalidateQueries({
    queryKey: userKeys.list,
    refetchType: 'active',
  });
};

function ResendInvitationButton(props: { id: string }) {
  const { id } = props;

  const { mutateAsync, isPending, isError, isSuccess } =
    useResendInvitationMutation(id);

  const handleClick = async () => {
    await mutateAsync();
  };

  if (isSuccess) {
    return <p className="text-sm">Invitation sent!</p>;
  }

  if (isError) {
    return <p className="text-sm text-red-500">Something went wrong</p>;
  }

  return (
    <button
      type="button"
      disabled={isPending}
      className="btn btn-secondary"
      onClick={handleClick}
    >
      Resend invitation
    </button>
  );
}

function PlatformUsers() {
  const { isLoading, data: users, error } = useUsers();
  const [modalActionId, setModalActionId] = useState<string | null>(null);
  const {
    isOpen: isDeleteModalOpen,
    openModal: openDeleteModal,
    closeModal: closeDeleteModal,
  } = useModal();
  const {
    mutateAsync: suspendUserMutation,
    isPending: isSuspendUserMutationLoading,
    error: suspendUserMutationError,
  } = useSuspendUserMutation({
    onSuccess: resetUsersQuery,
  });
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams<{ id?: string }>();
  const [search, setSearch] = useState('');

  const { mutateAsync: create, isPending: isCreating } =
    useCreateInvitationMutation({
      onSuccess: resetUsersQuery,
      retry: 0,
    });

  const { mutateAsync: update, isPending: isUpdating } = useUpdateUserMutation({
    onSuccess: resetUsersQuery,
    retry: 0,
  });

  const isUserListView = Boolean(
    matchPath(staffRouterPath.PLATFORM_USERS, location.pathname)
  );
  const isNewUser = Boolean(
    matchPath(staffRouterPath.NEW_PLATFORM_USER, location.pathname)
  );

  const isEditUser =
    Boolean(matchPath(staffRouterPath.PLATFORM_USER, location.pathname)) &&
    !isNewUser &&
    !isUserListView; // because of platform-users/new is mataching to platform-users/:id format

  const columnHelper = createColumnHelper<UsersAndInvites>();

  const getTagType = (value: UsersAndInvites['status']) => {
    switch (value) {
      case 'Active':
        return 'success';
      case 'Pending':
        return 'info';
      case 'Revoked':
        return 'error';
      default:
        return 'error';
    }
  };

  const openSuspendUser = (actionId: string) => () => {
    setModalActionId(actionId);
    openDeleteModal();
  };

  const handleSuspendUser = async () => {
    if (!modalActionId) {
      return;
    }

    await suspendUserMutation({
      userId: modalActionId,
      isSuspended: true,
    });

    closeSuspendUser();
  };

  const closeSuspendUser = () => {
    setModalActionId(null);
    closeDeleteModal();
  };

  const handleEdit = (id: string) => () => {
    const redirectUrl = generatePath(staffRouterPath.PLATFORM_USER, { id });
    navigate(redirectUrl);
  };

  const handleUpdate = async (dto: UpdateUserDto) => {
    return await update({ id: params.id || '', data: dto });
  };

  const columns = [
    columnHelper.accessor('name', {
      id: 'name',
      cell: info => (
        <span className="block text-sm">
          <span className="block font-medium text-gray-900">
            {info.row.original.name}
          </span>
          {info.row.original.email}
        </span>
      ),
      header: () => <span>Name</span>,
      enableSorting: true,
    }),
    columnHelper.accessor(row => row.userGroup.name, {
      id: 'userGroup',
      cell: info => info.getValue(),
      header: () => <span>User group</span>,
      size: 176,
    }),
    columnHelper.accessor('status', {
      header: 'Status',
      cell: info => (
        <Tag type={getTagType(info.getValue())}>{info.getValue()}</Tag>
      ),
      size: 120,
    }),
    columnHelper.display({
      id: 'id',
      cell: info => (
        <div className="flex justify-end space-x-0.5">
          {(info.row.original.status === 'Pending' ||
            info.row.original.status === 'Expired') && (
            <ResendInvitationButton id={info.row.original.id} />
          )}
          {info.row.original.status === 'Active' && (
            <>
              <PermissionGuard
                requiredPermissions={[Permission['users:suspend']]}
              >
                <button
                  type="button"
                  className="suspend-user icon-link"
                  onClick={openSuspendUser(info.row.original.id)}
                >
                  <UserX02
                    className="h-5 w-5"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Suspend user</span>
                </button>
              </PermissionGuard>
              <PermissionGuard
                requiredPermissions={[Permission['users:fetch']]}
              >
                <button
                  type="button"
                  className="edit-user icon-link"
                  onClick={handleEdit(info.row.original.id)}
                >
                  <Edit01
                    className="h-5 w-5"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  />
                  <span className="sr-only">Edit user</span>
                </button>
              </PermissionGuard>
            </>
          )}
        </div>
      ),
      size: 243,
    }),
  ];

  const filteredUsers = users?.filter(
    user =>
      user.name.toLowerCase().includes(search.toLowerCase()) ||
      user.email.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <div>
      <PageTitle
        title="Users"
        description="Manage your team members and their account permissions here."
        variant="white"
      >
        <PermissionGuard
          requiredPermissions={[Permission['invitation:invite']]}
        >
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => navigate(staffRouterPath.NEW_PLATFORM_USER)}
          >
            Invite User
          </button>
        </PermissionGuard>
      </PageTitle>

      <Tooltip
        anchorSelect=".edit-user"
        content="Edit User"
        place="top"
        className="tooltip"
      />

      <Tooltip
        anchorSelect=".suspend-user"
        content="Suspend User"
        place="top"
        className="tooltip"
      />

      <UpsertUserDrawer
        open={isNewUser}
        type="create"
        onSubmit={create}
        isLoading={isCreating}
      />

      <UpsertUserDrawer
        open={isEditUser}
        type="edit"
        onSubmit={handleUpdate}
        isLoading={isUpdating}
      />

      <Modal
        open={isDeleteModalOpen}
        onClose={closeSuspendUser}
        title="Confirm Suspend User"
        description="Suspending this user will revoke their access to the platform immediately. This action is reversible."
        primaryBtnText="Confirm Suspension"
        primaryAction={handleSuspendUser}
        secondaryBtnText="Cancel"
        secondaryAction={closeSuspendUser}
        type="error"
        error={getErrorMessage(suspendUserMutationError)}
        isLoading={isSuspendUserMutationLoading}
      />

      <TextInputBase
        type="search"
        placeholder="Search by name"
        className="mt-4 md:ml-auto md:w-[320px]"
        defaultValue={search}
        onChange={e => setSearch(e.target.value)}
        LeadingIcon={
          <SearchLg className="h-4 w-4 text-gray-500" viewBox="0 0 24 24" />
        }
      />
      <Table
        columns={columns}
        data={filteredUsers}
        paginationType="auto"
        isLoading={isLoading}
        error={getErrorMessage(error)}
      />
    </div>
  );
}
export { PlatformUsers, resetUsersQuery };
