import { useEffect, useState } from 'react';
import { getEnvPrefixFromEnv } from './utils/get-env-prefix-from-env';

function CookieBanner() {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const preference = document.cookie
      .split('; ')
      .find(row => row.startsWith('cookiePreference='));

    if (preference) {
      setIsVisible(false);
    }
  }, []);

  const setCookiePreference = (preference: 'accept' | 'decline') => {
    const expiryDate = new Date();
    expiryDate.setDate(expiryDate.getDate() + 7);

    document.cookie = `cookiePreference=${preference};path=/`;
    setIsVisible(false);
  };

  const handleAccept = () => {
    setCookiePreference('accept');
  };

  const handleDecline = () => {
    setCookiePreference('decline');
  };

  if (!isVisible) {
    return null;
  }

  const envPrefix = getEnvPrefixFromEnv();
  const privacyPolicyUrl = `https://admit-public-${envPrefix}.s3.eu-west-2.amazonaws.com/cookie-policy.pdf`;

  return (
    <div className="fixed bottom-6 left-1/2 z-10 w-full max-w-[660px] -translate-x-1/2 px-4">
      <div className=" rounded-xl border border-gray-200 bg-white p-3 shadow-lg">
        <div className="flex flex-col items-center gap-6 md:flex-row">
          <div className="flex h-[60px] w-[60px] min-w-[60px] items-center justify-center rounded-xl bg-gray-200">
            <img
              src="/cropped-Frame-4-180x180.png"
              alt=""
              className="h-8 w-8"
            />
          </div>
          <p className="text-center text-sm text-primary-900 md:text-left">
            We use cookies to collect data to improve your experience on our
            site. Read our{' '}
            <a
              href={privacyPolicyUrl}
              className="underline"
              target="_blank"
              rel="noreferrer"
            >
              Cookie Policy
            </a>
            .
          </p>
          <div className="flex items-center gap-4">
            <button className="btn shadow-none" onClick={handleDecline}>
              Decline
            </button>
            <button className="btn btn-primary" onClick={handleAccept}>
              Accept
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export { CookieBanner };
